function trackEvent(eventName, data) {
  if (typeof window === "undefined" || !window.gtag) {
    return false;
  }
  window.gtag("event", eventName, { ...data });
  return false;
}

function captureOutboundLink(url) {
  trackEvent("click", {
    event_category: "outbound",
    event_label: url,
    transport_type: "beacon",
    // event_callback: function() {
    //   document.location = url;
    // }
  });
  return false;
}

export default trackEvent;
export { captureOutboundLink };
