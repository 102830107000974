import React from 'react';

const Arrow = ({ orientation = null, ...props }) => (
  <svg
    viewBox="0 0 46 15"
    width="1em"
    height="1em"
    {...props}
    style={
      orientation === -1 || orientation === 1
        ? { transform: `scaleX(${orientation})` }
        : null
    }
  >
    <title>arrow</title>
    <path
      fill={props.fill || `#444`}
      fillRule="evenodd"
      d="M38.334 0l-1.352 1.322 5.348 5.241H0v1.875h42.33l-5.348 5.24L38.334 15 46 7.5z"
    />
  </svg>
);

export default Arrow;
